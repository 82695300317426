import React from "react";
import {
    Grid, Card, Text, Row,
} from "@nextui-org/react";

import {
    CssBaseline, GeistProvider, Themes,
} from "@geist-ui/core";

const myTheme1 = Themes.createFromLight({
    type: "coolTheme",
    palette: {
        warning: "#ffa62b",
        success: "#70e000",
    },
});

export default function ContentChipGrid(props) {


    return (

        <>

            <Grid.Container gap={2} justify="center">
                <GeistProvider themes={[myTheme1]} themeType="coolTheme">
                    <CssBaseline />
                    <Grid xs={12} sm={4} md={3}>
                        <Card css={{ backgroundColor: '#02c39a' }} key="filteredRow">
                            <Card.Body
                                css={{ w: 'auto', display: 'flex', justifyContent: 'center' }}
                            >
                                <Row justify="center" align="center">
                                    <Text size={20} b css={{ m: 0, color: 'white' }}>
                                        1
                                    </Text>
                                </Row>
                                <Row justify="center" align="center">
                                    <Text size={15} css={{ m: 0, color: 'white' }}>
                                        Completed
                                    </Text>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Grid>
                    <Grid xs={12} sm={4} md={3} key={`ModalGrid-1`}>
                        <Card css={{ backgroundColor: '#05668d' }} key="filteredSuccessRow">
                            <Card.Body
                                css={{ w: 'auto', display: 'flex', justifyContent: 'center' }}
                            >
                                <Row justify="center" align="center">
                                    <Text size={20} b css={{ m: 0, color: 'white' }}>
                                        2
                                    </Text>
                                </Row>
                                <Row justify="center" align="center">
                                    <Text size={15} css={{ m: 0, color: 'white' }}>
                                        Closed
                                    </Text>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Grid>
                    <Grid xs={12} sm={4} md={3} key={`ModalGrid-2`}>
                        <Card css={{ backgroundColor: '#FC582A' }} key="filteredFailedRowGrid">
                            <Card.Body
                                css={{ w: 'auto', display: 'flex', justifyContent: 'center' }}
                            >
                                <Row justify="center" align="center">
                                    <Text size={20} b css={{ m: 0, color: 'white' }}>
                                        0
                                    </Text>
                                </Row>
                                <Row justify="center" align="center">
                                    <Text size={15} css={{ m: 0, color: 'white' }}>
                                        Failed
                                    </Text>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Grid>

                </GeistProvider>
            </Grid.Container>
        </>
    );



}
