import React, { useState, useEffect } from "react";
import { Modal, Button, Text, Grid } from "@nextui-org/react";
import { Check, X } from '@geist-ui/icons'
import api from "../api/api";
import LoadingComponent from "./LoadingComponent";
import classes from '../styles/CustomPopup.module.css';
import ErrorComponent from "./ErrorComponent";

const MESSAGE = "Decision: Reject\n\nReasoning: \n- The patient has been diagnosed with IBS and has been prescribed Linaclotide before.\n- The patient has mild asthma and atopy, but there are no known interactions between Linaclotide and Telfast or Ventolin.\n- The patient has no known allergies to Linaclotide.\n- The patient has no known cardio-vascular conditions or symptoms, and has not recently experienced any concerning symptoms.\n- The patient is not pregnant or breastfeeding.\n- The patient has confirmed that they will consult their doctor if they experience any concerning symptoms.\n- The patient has confirmed their understanding and acceptance of the medication and its patient information leaflet.\n\nWarning: None.";

export default function CustomPopup({ rowId, itemId, updateConfirmation, product }) {
    const [visible, setVisible] = useState(false);
    const [unspecifyContext, setUnspecifyContext] = useState('');
    const [decision, setDecision] = useState('');
    const [warning, setWarning] = useState([]);
    const [reasonList, setReasonList] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false)

    const handler = async () => {
        setVisible(true);
        // console.log('product:- ' , product)
        await evaluateProduct();

    };

    const evaluateProduct = async () => {
        try {
            setIsError(false);
            setIsLoading(true);
            //Test with api response
            const response = await api.post('/evaluate', product);
            const message = response.data.message;

            //Test with Static Data
            //const message = MESSAGE;
            //console.log('message:- ' , message);
            if (message.includes('Decision') || message.includes('Reasoning') || message.includes('Warning')) {

                const decision = message.split('Decision:')[1].trim().split('Reasoning:')[0];
                setDecision(decision);
                const reasoning = message.replace(/"off-label\.+"/g, 'off-label.').split('Reasoning:')[1].split('Warning:')[0].trim().split('.').map((item) => {
                    return item.trim().replace('-', '').trim();
                })
                setReasonList(reasoning)

                const warning = message.replace(/"off-label\.+"/g, 'off-label.').split('Warning:')[1].trim().split('.').map((item) => {
                    return item.trim().replace('-', '').trim();
                })
                //console.log('warning:- ' , reasoning);
                setWarning(warning);

            } else {
                setUnspecifyContext(message);
            }
            setIsLoading(false);
        } catch (error) {
            //setError(error);
            console.log('Error in Evaluate Method');

            setIsLoading(false);
            setIsError(true);
        }
    };

    const handleConfirmationChange = (item, newConfirmation) => {
        // Call the callback function passed from the parent
        updateConfirmation(item, newConfirmation);
    };


    const closeHandler = (item, status) => {
        if (status === 'approve') {
            handleConfirmationChange(item, 'approve');
        } else if (status === 'reject') {
            handleConfirmationChange(item, 'reject');
        }

        setVisible(false);

    };
    return (
        <>
            <Button auto light color="secondary" onPress={handler}>
                Evaluate
            </Button>
            <Modal
                closeButton
                preventClose
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
                width="500px"
            >
                {/* <Modal.Body> */}

                {isLoading && <>
                    <Modal.Body>
                        <Grid.Container gap={2} justify="center">
                            <LoadingComponent message={'Sit back, Relax, and let the AI Magic Unfold...'} />
                        </Grid.Container>
                    </Modal.Body>
                </>}

                {(!isLoading && isError) && <>
                    <Modal.Body>
                        <ErrorComponent message={'Oops! It seems we\'ve hit a hiccup. Please Try Again Later!'} paddingTop={0} />
                    </Modal.Body>
                </>}

                {(!isLoading && !isError) && <>
                    <>
                        <Modal.Body>
                            <Grid.Container >
                                {unspecifyContext !== '' ? (
                                    <>
                                        <Text id="modal-content1" size={14}>
                                            {unspecifyContext}
                                        </Text>
                                    </>
                                ) : (
                                    <div>
                                        <div  >
                                            <p id="modal-content2" size={14} >
                                                <span style={{ fontWeight: 700, }}>Decision :</span> <span className={`${classes.decisionText} ${(decision.includes('Accept') || decision.includes('Approve') || decision.includes('accept') || decision.includes('approve')) && classes.decisionApprove} ${(decision.includes('Reject') || decision.includes('reject')) && classes.decisionReject}`}> {decision}</span>
                                            </p>
                                        </div>


                                        <div id="modal-content3" size={14}>
                                            <span style={{ fontWeight: 700 }}>Reasoning :</span> <br />

                                            {reasonList &&
                                                reasonList.length > 0 &&
                                                reasonList.map((item, index) => {
                                                    if (item.trim() !== '' && isNaN(item.trim())) {
                                                        return (

                                                            <div key={`div-${index}-${item}`} className={classes.listView} >
                                                                <li key={`li-${index}-${item}`} style={{ paddingBottom: '5px', fontSize: '15px' }}>
                                                                    {`${item}`}
                                                                </li>

                                                            </div>


                                                        );
                                                    }
                                                    return null;
                                                })}


                                        </div>

                                        {(warning[0] === ('None') || warning[0] === ('none')) ? (
                                            <>
                                                <p id="modal-content4" size={14}>
                                                    <span style={{ fontWeight: 700 }}>Warning : </span><span className={`${classes.warningText} ${classes.warningNone}`}>{warning}</span>
                                                </p>
                                            </>
                                        ) : (<>
                                            <div id="modal-content4" size={14}>
                                                <span style={{ fontWeight: 700 }}>Warning : </span><br />
                                                
                                                <div className={`${classes.warningText} ${classes.warningColored}`}>
                                                {warning &&
                                                warning.length > 0 &&
                                                warning.map((item, index) => {
                                                    if (item.trim() !== '' && isNaN(item.trim()) ) {
                                                        return (

                                                            <div key={`div-${index}-${item}`} className={`${classes.listView}`} >
                                                                <li key={`li-${index}-${item}`} style={{ fontSize: '15px' }}>
                                                                {item}
                                                                </li>

                                                            </div>


                                                        );
                                                    }
                                                    return null;
                                                })}
                                                </div>
                                               
                                                {/* <span className={`${classes.warningText} ${classes.warningColored}`}></span> */}
                                            </div>
                                        </>)}

                                    </div>
                                )}

                            </Grid.Container>
                            <Grid.Container gap={1} justify="center">
                                <Grid xs={12} sm={6}>
                                    <Button auto flat color="success" disabled={isLoading} onPress={() => closeHandler(itemId, 'approve')} style={{ width: '100%' }} icon={<Check />}>
                                        Approve
                                    </Button>
                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <Button auto color="error" disabled={isLoading} onPress={() => closeHandler(itemId, 'reject')} style={{ width: '100%' }} icon={<X />}>
                                        Reject
                                    </Button>
                                </Grid>
                            </Grid.Container>
                        </Modal.Body>
                    </>
                </>}

                {/* {isLoading ? (
                        <>
                            <Grid.Container gap={2} justify="center">
                                <LoadingComponent message={'Sit back, Relax, and let the AI Magic Unfold...'} />
                            </Grid.Container>
                        </>
                    ) : (
                        
                        <>
                            <Grid.Container >
                                {unspecifyContext !== '' ? (
                                    <>
                                        <Text id="modal-content1" size={14}>
                                            {unspecifyContext}
                                        </Text>
                                    </>
                                ) : (
                                    <div>
                                        <div  >
                                            <p id="modal-content2" size={14} >
                                                <span style={{ fontWeight: 700, }}>Decision :</span> <span className={`${classes.decisionText} ${(decision.includes('Accept') || decision.includes('Approve') || decision.includes('accept') || decision.includes('approve')) && classes.decisionApprove} ${(decision.includes('Reject') || decision.includes('reject')) && classes.decisionReject}`}> {decision}</span>
                                            </p>
                                        </div>


                                        <div id="modal-content3" size={14}>
                                            <span style={{ fontWeight: 700 }}>Reasoning :</span> <br />

                                            {reasonList &&
                                                reasonList.length > 0 &&
                                                reasonList.map((item, index) => {
                                                    if (item.trim() !== '') {
                                                        return (

                                                            <div key={`div-${index}-${item}`} className={classes.listView} >
                                                                <li key={`li-${index}-${item}`} style={{ paddingBottom: '5px', fontSize: '15px' }}>
                                                                    {`${item}`}
                                                                </li>

                                                            </div>


                                                        );
                                                    }
                                                    return null;
                                                })}


                                        </div>

                                        <p id="modal-content4" size={14}>
                                            <span style={{ fontWeight: 700 }}>Warning : </span><span className={`${classes.warningText} ${(warning.startsWith('None') || warning.startsWith('none')) && classes.warningNone}`}>{warning}</span>
                                        </p>
                                    </div>
                                )}

                            </Grid.Container>
                        </>
                    )} */}


                {/* </Modal.Body> */}
            </Modal>
        </>
    );
}
