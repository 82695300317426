import React, { useState, useEffect } from 'react';

import { Spacer } from '@nextui-org/react';

// NextUI Imports
import api from '../api/api';

// comp imports
import ContentChipGrid from '../components/ContentChipGrid';
import ContentTable from '../components/ContentTable';
import CustomTable from '../components/CustomTable';
import CustomPopup from '../components/CustomPopup';


export default function Dashboard() {
    

  //console.log('values:- ' , initial);
  return (
    <>
      <div style={{marginTop : '20px'}}>
      <ContentChipGrid />
      </div>
      
      {/* <ModalChipGrid modalcontent={initial.modal} /> */}
      <Spacer y={2} />
      <ContentTable />
    </>
  );
}
