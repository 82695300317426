import React from "react";
import { Text  } from "@nextui-org/react";
import { Frown } from '@geist-ui/icons'

export default function ErrorComponent({ message , paddingTop }) {
   return (
      <div
         style={{
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
            paddingTop
         }}
      >
            
          
            <Frown size={36} />
            <p style={{fontWeight : 700 , marginTop : 0}} >{message}</p>
      
            

      </div>
   );
}
