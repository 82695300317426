import React from 'react';
import { Text } from '@nextui-org/react';
import classes from '../styles/ContentTable.module.css';

import TableDetails from './TableDetails';

export default function ContentTable() {



  return (
    <div className={classes.Content}>
      <Text b size={26} >Recent Orders</Text>
      {/* 
        <>
          {!date && <TableDetails tableRows={tablecontent} />}
          {date && !filterData && <TableDetails tableRows={output} />}
          {date && filterData && <TableDetails tableRows={filterData.result} />}
        </>
      */}
      <div style={{marginTop : '20px'}}>
      <TableDetails />
      </div>
      
    </div>
  );
}
