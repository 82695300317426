import React, { useState, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from '@tanstack/react-table';
import CustomPopup from './CustomPopup';
import { Check, X, QuestionCircle } from '@geist-ui/icons';
import { Avatar } from "@nextui-org/react";
import api from '../api/api';
import LoadingComponent from './LoadingComponent';
import ErrorComponent from './ErrorComponent';

const defaultColumns = [
    {
        header: 'Product',
        accessorKey: 'product_name',
        cell: (info) => info.getValue(),
        size: 300,
    },
    {
        header: 'Category',
        accessorKey: 'category_name',
        cell: (info) => info.getValue(),
        size: 300,
    },
    {
        header: 'AI',
        accessorKey: 'ai',
        cell: (info) => info.getValue(),
        size: 200,
    },
    {
        header: 'Confirmation',
        accessorKey: 'confirmation',
        cell: (info) => info.getValue(),
        size: 300,
    },
];


const TABLE_ROWS = [
    {
        "product_name": "Linaclotide",
        "category_name": "AH - IBS",
        "general_questions": [
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "Yes"
            },
            {
                "Question": "If Yes",
                "Answer": "Very mild asthma and atopy"
            },
            {
                "Question": "Are you on any other medication (including prescription medicine, over the counter medicines, vitamins or supplements)?",
                "Answer": "Yes"
            },
            {
                "Question": "If Yes",
                "Answer": "Telfast, and ventolin prn"
            },
            {
                "Question": "Do you have any allergies?",
                "Answer": "Yes"
            },
            {
                "Question": "If Yes",
                "Answer": "House dust mite"
            },
            {
                "Question": "Are you registered with a GP in the UK?",
                "Answer": "No"
            },
            {
                "Question": "Would you like to upload any documents for the prescriber to see (for example, prescription, doctors letter or blood tests)?",
                "Answer": "Yes"
            },
            {
                "Question": "Uploaded File",
                "Answer": "Doc 1 Doc 2"
            },
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            }
        ],
        "category_questions": [
            {
                "Question": "Have you been diagnosed as suffering from IBS?",
                "Answer": "Yes"
            },
            {
                "Question": "When was this diagnosis made?",
                "Answer": "20 years ago"
            },
            {
                "Question": "Who made this diagnosis?",
                "Answer": "Specialist"
            },
            {
                "Question": "When was your condition last reviewed by your doctor?",
                "Answer": "3 months ago"
            },
            {
                "Question": "What symptoms of IBS do you experience?",
                "Answer": "Bloating, Constipation"
            },
            {
                "Question": "How do you manage the symptoms of your condition?",
                "Answer": "Psyllium husk, exercise"
            },
            {
                "Question": "Do you suffer from any of the following cardio-vascular conditions or symptoms: Hypotension (low blood pressure), Cardiogenic or circulatory shock, Left ventricular failure, Low blood volume, Pulmonary oedema (a build-up of fluid in the lungs), Heart failure, Inflammation of the heart, Build up of fluid around the heart, Narrowing of the heart valves, Hypertrophic cardiomyopathy",
                "Answer": "No"
            },
            {
                "Question": "Have you recently experienced any of the following? Unexplained Weight loss, Rectal bleeding or blood in your stool, A hard lump or swelling in your tummy",
                "Answer": "No"
            },
            {
                "Question": "Are you pregnant or breast feeding? Or do you intend to become pregnant?",
                "Answer": "No"
            },
            {
                "Question": "Anything else you would like us to know about your condition or your health? (Please tell us anything you think may be important for the prescriber to be aware)",
                "Answer": "No"
            }
        ],
        "product_questions": [
            {
                "Question": "Have you ever been prescribed this item?:",
                "Answer": "Yes"
            },
            {
                "Question": "How long ago were you first prescribed this medicine:",
                "Answer": "3 months - 12 months"
            },
            {
                "Question": "When were you last prescribed this item?:",
                "Answer": "Mar-23"
            },
            {
                "Question": "What was the prescribers directions/dosage? (For example, Use three times a day):",
                "Answer": "290mcg, once daily"
            },
            {
                "Question": "When did you last have a review of this medication with your normal doctor?:",
                "Answer": "4 month ago"
            },
            {
                "Question": "Were any issues raised at your last review?:",
                "Answer": "No"
            },
            {
                "Question": "Are you currently under the care of a clinician who monitors your treatment with this medicine?:",
                "Answer": "Yes"
            },
            {
                "Question": "Why are you ordering this item from Pharmacy Planet today?:",
                "Answer": "refill of script"
            },
            {
                "Question": "Do you suffer from any of the following condition? # Unexplained weight loss # Difficulty swallowing # Liver or kidney disease # Severe or persistent diarrhoea # Black stools (blood stained fasces) # Vomiting food or blood # Sugar intolerance # History of:",
                "Answer": "No"
            },
            {
                "Question": "Do you undertake regular blood tests to monitor your body's response to this medicine?:",
                "Answer": "No"
            },
            {
                "Question": "Please confirm that you will consult your usual doctor immediately if you experience any of the following symptoms: # Fever, sore throat, mouth or lip ulcers, spots underneath your skin anywhere on your body, including the genital and anal regions, become:",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm your understanding and acceptance of the following: # You are ordering the medication for yourself. # You have answered the questions truthfully. # Before taking the medicine you will read the enclosed patient information leaflet and follow:",
                "Answer": "Yes"
            },
            {
                "Question": "Brand:",
                "Answer": "Constella"
            },
            {
                "Question": "Medicine Strength:",
                "Answer": "290mcg"
            },
            {
                "Question": "Size:",
                "Answer": "84 Capsules"
            }
        ]
    },
    {
        "product_name": "Ozempic for Weight Loss",
        "category_name": "Weight Loss",
        "general_questions": [],
        "category_questions": [],
        "product_questions": []
    },
    {
        "product_name": "Ozempic for Weight Loss",
        "category_name": "V - Weight Loss",
        "general_questions": [
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "Yes"
            },
            {
                "Question": "If Yes",
                "Answer": "Type 2 diabetics and Hypertension"
            },
            {
                "Question": "Are you on any other medication (including prescription medicine, over the counter medicines, vitamins or supplements)?",
                "Answer": "Yes"
            },
            {
                "Question": "If Yes",
                "Answer": "Hypertension meds working well"
            },
            {
                "Question": "Do you have any allergies?",
                "Answer": "No"
            },
            {
                "Question": "Are you registered with a GP in the UK?",
                "Answer": "No"
            },
            {
                "Question": "Would you like to upload any documents for the prescriber to see (for example, prescription, doctors letter or blood tests)?",
                "Answer": "No"
            },
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            }
        ],
        "category_questions": [
            {
                "Question": "Have you previously tried unsuccessfully to lose weight?",
                "Answer": "Yes"
            },
            {
                "Question": "Please describe what efforts you've made in the past",
                "Answer": "Diet"
            },
            {
                "Question": "Please provide your height",
                "Answer": "6 ft as before"
            },
            {
                "Question": "Please provide your weight",
                "Answer": "18 stone 2"
            },
            {
                "Question": "When was this measurement taken?",
                "Answer": "Today"
            },
            {
                "Question": "Do you suffer from any of the following conditions: diabetes or pre-diabetes, Hypertension or high blood pressure, High cholesterol, Obstructive sleep apnoea",
                "Answer": "Yes"
            },
            {
                "Question": "Please provide further details below:",
                "Answer": "Type 2 Hypertension As before"
            },
            {
                "Question": "Do you have a history of anorexia, bulimia or any other eating disorder?",
                "Answer": "No"
            },
            {
                "Question": "Are you pregnant, planning pregnancy or breast-feeding?",
                "Answer": "No"
            },
            {
                "Question": "Anything else you would like us to know? (Please tell us anything you think may be important for the prescriber to be aware)",
                "Answer": "No"
            }
        ],
        "product_questions": [
            {
                "Question": "Are you using Ozempic currently?:",
                "Answer": "Yes"
            },
            {
                "Question": "If yes, where did you last purchase Ozempic from?:",
                "Answer": "Pharmacy Planet"
            },
            {
                "Question": "What Dosage are you currently on?:",
                "Answer": "1mg"
            },
            {
                "Question": "How long have you been using Ozempic?:",
                "Answer": "6 weeks"
            },
            {
                "Question": "How Much weight have you lost since starting on Ozempic?:",
                "Answer": "6 lbs"
            },
            {
                "Question": "Are you happy with your progress?:",
                "Answer": "Yes"
            },
            {
                "Question": "Are you having any untoward side effects?:",
                "Answer": "No"
            },
            {
                "Question": "Have you been diagnosed as a diabetic?:",
                "Answer": "Yes"
            },
            {
                "Question": "If yes, when were you first diagnosed with diabetes?:",
                "Answer": "Per previous consultation 9 years ago"
            },
            {
                "Question": "Please give details of your diabetic history and management:",
                "Answer": "Diet"
            },
            {
                "Question": "Are you on any medication for diabetes? (For example, tablets, insulin, etc) Please give details:",
                "Answer": "No"
            },
            {
                "Question": "Have you been asked to monitor your blood sugar levels using a Blood Glucose Monitor?:",
                "Answer": "Yes"
            },
            {
                "Question": "If yes, on average, how many times do you test your blood sugar levels per day?:",
                "Answer": "Once a week"
            },
            {
                "Question": "Have you ever suffered from pancreatitis, or have you ever had any pancreas related issues? :",
                "Answer": "No"
            },
            {
                "Question": "Have you ever suffered from a thyroid condition or any thyroid related illnesses? :",
                "Answer": "No"
            },
            {
                "Question": "Do you suffer from any of the following conditions? # Heart Condition # Kidney Problems # Liver Problems # Obesity Secondary to Endocrinological, or Eating Disorders, or Treatment With Medicinal Products That May Cause Weight Gain # Inflammatory Bowel Dis:",
                "Answer": "No"
            },
            {
                "Question": "Please confirm that you are aware that use of this medicine will require regular and ongoing monitoring by our clinical team that will take place via remote video consultation. :",
                "Answer": "Yes"
            },
            {
                "Question": "Please describe your level of physical activity:",
                "Answer": "Moderate (Between 30 and 60 minutes per week)"
            },
            {
                "Question": "Are you fully aware of how to administer this medication?:",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you are aware that this medicine is prescribed \"off-label\":",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you are aware that this medicine requires regular exercise and a sensible diet for optimal results to be achieved?:",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you will speak to your doctor at once if you develop any of the following symptoms or conditions: # Symptoms of a severe allergic reaction (such as breathing problems, swelling of face and throat and a fast heartbeat) # Symptoms of Pan:",
                "Answer": "Yes"
            },
            {
                "Question": "Are you familiar with the symptoms of hypoglycaemia and what action to take if you experience these symptoms? Symptoms of Hypoglycaemia include: # Sweating # Feeling tired or sleepy # Dizziness # Feeling hungry # Tingling lips # Feeling shaky or trembling:",
                "Answer": "Yes"
            },
            {
                "Question": "At present, are you suffering from fever or feeling unwell?:",
                "Answer": "No"
            },
            {
                "Question": "Please confirm that you are aware that this medicine requires refrigeration? (Your order will be shipped with cold packs in place but should be unpacked and refrigerated as soon as it is received):",
                "Answer": "Yes"
            },
            {
                "Question": "Are you pregnant, breast feeding or planning to become pregnant in the next six months?:",
                "Answer": "No"
            },
            {
                "Question": "Anything else you would like us to know? (Please tell us anything you think may be important for the prescriber to be aware):",
                "Answer": "No"
            },
            {
                "Question": "If this medicine is supplied, we are required to inform your regular doctor of this supply. Please can you provide your physician's name and address below::",
                "Answer": "As before like photo passport etc … can this process be adapted for returning customers please ?"
            },
            {
                "Question": "Please confirm acceptance of the following: # You are ordering the medication for yourself. # You have answered the questions truthfully. # Before taking the medicine you will read the enclosed patient information leaflet and follow the guidance issued b:",
                "Answer": "Yes"
            },
            {
                "Question": "Please upload a full head-to-toe body picture. The picture must satisfy the following: #It must be a head-to-toe photo, including face #It must show your figure accurately, clothes must not be loose or baggy #It must not contain items or accessories:",
                "Answer": "IMG_0088.jpeg 4032 x 3024 px."
            },
            {
                "Question": "Please upload a piece of photo ID for confirmation of your identity (eg passport, license, biometric identity card). :",
                "Answer": "IMG_20220602_115646_Original.jpeg 2736 x 2736 px."
            },
            {
                "Question": "Medicine Strength:",
                "Answer": "Continuation Pack 1mg ( 2 x 1.0mg)"
            },
            {
                "Question": "Size:",
                "Answer": "1 Pack"
            }
        ]
    },
    {
        "product_name": "Ganfort/Bimatoprost+Timolol",
        "category_name": "AJ - GLAUCOMA",
        "general_questions": [
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            },
            {
                "Question": "Are you on any other medication (including prescription medicine, over the counter medicines, vitamins or supplements)?",
                "Answer": "No"
            },
            {
                "Question": "Do you have any allergies?",
                "Answer": "No"
            },
            {
                "Question": "Are you registered with a GP in the UK?",
                "Answer": "No"
            },
            {
                "Question": "Would you like to upload any documents for the prescriber to see (for example, prescription, doctors letter or blood tests)?",
                "Answer": "Yes"
            },
            {
                "Question": "Uploaded File",
                "Answer": "Doc 1"
            },
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            }
        ],
        "category_questions": [
            {
                "Question": "Question : Have you been diagnosed as suffering from glaucoma or raised intraocular pressure?",
                "Answer": "Yes"
            },
            {
                "Question": "Question : When was this diagnosis made?",
                "Answer": "4/1/2020"
            },
            {
                "Question": "Question : Who made this diagnosis?",
                "Answer": "Specialist"
            },
            {
                "Question": "Question : When was your condition last reviewed by your doctor?",
                "Answer": "6/1/2023"
            },
            {
                "Question": "Question : Do you currently or have you ever had any of the following:- An infection of the eye caused by Herpes Simplex Virus, Cataracts, Ulcers of they eye (shingles), Any condition or disorder causing a thinning of the eye tissues, Surgery of the eye (including cataract surgery), A history of eye disease or injury",
                "Answer": "No"
            },
            {
                "Question": "Question : Are you pregnant or breast feeding? Or do you intend to become pregnant?",
                "Answer": "No"
            },
            {
                "Question": "Question : Anything else you would like us to know about your condition or your health? (Please tell us anything you think may be important for the prescriber to be aware)",
                "Answer": "No"
            }
        ],
        "product_questions": [
            {
                "Question": "Have you ever been prescribed this item?:",
                "Answer": "Yes"
            },
            {
                "Question": "How long ago were you first prescribed this medicine:",
                "Answer": "more than a year"
            },
            {
                "Question": "When were you last prescribed this item?:",
                "Answer": "6/1/2023"
            },
            {
                "Question": "What was the prescribers directions/dosage? (For example, Use three times a day):",
                "Answer": "once a day"
            },
            {
                "Question": "When did you last have a review of this medication with your normal doctor?:",
                "Answer": "6/1/2023"
            },
            {
                "Question": "Were any issues raised at your last review?:",
                "Answer": "No"
            },
            {
                "Question": "Are you currently under the care of a clinician who monitors your treatment with this medicine?:",
                "Answer": "No"
            },
            {
                "Question": "Why are you ordering this item from Pharmacy Planet today?:",
                "Answer": "Supply of medicine"
            },
            {
                "Question": "Have you noticed any change or worsening of your condition (For example, pus, discharge or pain):",
                "Answer": "No"
            },
            {
                "Question": "Please confirm your understanding and acceptance of the following: # You are ordering the medication for yourself. # You have answered the questions truthfully. # Before taking the medicine you will read the enclosed patient information leaflet and follow:",
                "Answer": "Yes"
            },
            {
                "Question": "Brand:",
                "Answer": "Unit Dose 0.4ml"
            },
            {
                "Question": "Size:",
                "Answer": "0.4ml x 90"
            }
        ]
    },
    {
        "product_name": "Ozempic for Weight Loss",
        "category_name": "Weight Loss",
        "general_questions": [
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            },
            {
                "Question": "Are you on any other medication (including prescription medicine, over the counter medicines, vitamins or supplements)?",
                "Answer": "No"
            },
            {
                "Question": "Do you have any allergies?",
                "Answer": "No"
            },
            {
                "Question": "Are you registered with a GP in the UK?",
                "Answer": "Yes"
            },
            {
                "Question": "If yes, Do you give permission for Pharmacy Planet to inform your GP of this supply?",
                "Answer": "No"
            },
            {
                "Question": "Would you like to upload any documents for the prescriber to see (for example, prescription, doctors letter or blood tests)?",
                "Answer": "No"
            },
            {
                "Question": "Do you suffer from or have you been diagnosed with any other medical condition(s)?",
                "Answer": "No"
            }
        ],
        "category_questions": [
            {
                "Question": "Have you previously tried unsuccessfully to lose weight?",
                "Answer": "Yes"
            },
            {
                "Question": "Please describe what efforts you've made in the past",
                "Answer": "Calorie counting"
            },
            {
                "Question": "Please provide your height",
                "Answer": "5ft5"
            },
            {
                "Question": "Please provide your weight",
                "Answer": 12.6
            },
            {
                "Question": "When was this measurement taken?",
                "Answer": "6/15/2023"
            },
            {
                "Question": "Do you suffer from any of the following conditions: diabetes or pre-diabetes, Hypertension or high blood pressure, High cholesterol, Obstructive sleep apnoea",
                "Answer": "No"
            },
            {
                "Question": "Do you have a history of anorexia, bulimia or any other eating disorder?",
                "Answer": "No"
            },
            {
                "Question": "Are you pregnant, planning pregnancy or breast-feeding?",
                "Answer": "No"
            },
            {
                "Question": "Anything else you would like us to know? (Please tell us anything you think may be important for the prescriber to be aware)",
                "Answer": "No"
            }
        ],
        "product_questions": [
            {
                "Question": "Are you using Ozempic currently?:",
                "Answer": "Yes"
            },
            {
                "Question": "If yes, where did you last purchase Ozempic from?:",
                "Answer": "Other"
            },
            {
                "Question": "Please select from one of the options below::",
                "Answer": "Other"
            },
            {
                "Question": "What Dosage are you currently on?:",
                "Answer": "0.5mg"
            },
            {
                "Question": "How long have you been using Ozempic?:",
                "Answer": "1 month"
            },
            {
                "Question": "How Much weight have you lost since starting on Ozempic?:",
                "Answer": "4lb"
            },
            {
                "Question": "Are you happy with your progress?:",
                "Answer": "Yes"
            },
            {
                "Question": "Are you having any untoward side effects?:",
                "Answer": "No"
            },
            {
                "Question": "Have you been diagnosed as a diabetic?:",
                "Answer": "No"
            },
            {
                "Question": "Have you ever suffered from pancreatitis, or have you ever had any pancreas related issues? :",
                "Answer": "No"
            },
            {
                "Question": "Have you ever suffered from a thyroid condition or any thyroid related illnesses? :",
                "Answer": "No"
            },
            {
                "Question": "Do you suffer from any of the following conditions? # Heart Condition # Kidney Problems # Liver Problems # Obesity Secondary to Endocrinological, or Eating Disorders, or Treatment With Medicinal Products That May Cause Weight Gain # Inflammatory Bowel Dis:",
                "Answer": "No"
            },
            {
                "Question": "Please confirm that you are aware that use of this medicine will require regular and ongoing monitoring by our clinical team that will take place via remote video consultation. :",
                "Answer": "Yes"
            },
            {
                "Question": "Please describe your level of physical activity:",
                "Answer": "Active (Between 60 and 150 minutes per week)"
            },
            {
                "Question": "Active (Between 60 and 150 minutes per week)",
                "Answer": ""
            },
            {
                "Question": "Are you fully aware of how to administer this medication?:",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you are aware that this medicine is prescribed \"off-label\":",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you are aware that this medicine requires regular exercise and a sensible diet for optimal results to be achieved?:",
                "Answer": "Yes"
            },
            {
                "Question": "Please confirm that you will speak to your doctor at once if you develop any of the following symptoms or conditions: # Symptoms of a severe allergic reaction (such as breathing problems, swelling of face and throat and a fast heartbeat) # Symptoms of Pan:",
                "Answer": "Yes"
            },
            {
                "Question": "Are you familiar with the symptoms of hypoglycaemia and what action to take if you experience these symptoms? Symptoms of Hypoglycaemia include: # Sweating # Feeling tired or sleepy # Dizziness # Feeling hungry # Tingling lips # Feeling shaky or trembling:",
                "Answer": "Yes"
            },
            {
                "Question": "At present, are you suffering from fever or feeling unwell?:",
                "Answer": "No"
            },
            {
                "Question": "Please confirm that you are aware that this medicine requires refrigeration? (Your order will be shipped with cold packs in place but should be unpacked and refrigerated as soon as it is received):",
                "Answer": "Yes"
            },
            {
                "Question": "Are you pregnant, breast feeding or planning to become pregnant in the next six months?:",
                "Answer": "No"
            },
            {
                "Question": "Anything else you would like us to know? (Please tell us anything you think may be important for the prescriber to be aware):",
                "Answer": "No"
            },
            {
                "Question": "If this medicine is supplied, we are required to inform your regular doctor of this supply. Please can you provide your physician's name and address below::",
                "Answer": "Dr Murphy clough surgery Co Down"
            },
            {
                "Question": "Please confirm acceptance of the following: # You are ordering the medication for yourself. # You have answered the questions truthfully. # Before taking the medicine you will read the enclosed patient information leaflet and follow the guidance issued b:",
                "Answer": "Yes"
            },
            {
                "Question": "Please upload a full head-to-toe body picture. The picture must satisfy the following: #It must be a head-to-toe photo, including face #It must show your figure accurately, clothes must not be loose or baggy #It must not contain items or accessories:",
                "Answer": "56E7113C-A702-42D5-8CD4-76349AB0D148.jpeg 4032 x 3024 px."
            },
            {
                "Question": "Please upload a piece of photo ID for confirmation of your identity (eg passport, license, biometric identity card). :",
                "Answer": "image.jpg 4032 x 3024 px."
            },
            {
                "Question": "Medicine Strength:",
                "Answer": "0.5mg"
            },
            {
                "Question": "Size:",
                "Answer": "1 Pack"
            }
        ]
    }
];

export default function TableDetails() {
    const [columns] = useState(() => [...defaultColumns]);
    const [tableRows, setTableRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError , setIsError] = useState(false);

    
    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        const getTableData = async () => {
            try {
                const response = await api.get('/requests');
                if (response.data && response.data.length > 0) {
                    const tableDataWithId = response.data.map((item, index) => ({
                        ...item,
                        id: index
                    }));
                    setTableRows(tableDataWithId);
                } else {
                    setTableRows([]);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                console.log('Error in Fetching data for table');
            }
        };

        getTableData();
    }, []);

    const table = useReactTable({
        data: tableRows,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const updateConfirmation = (itemId, newConfirmation) => {
        const updatedRows = tableRows.map((row) => {
            if (row.id === itemId) {
                return { ...row, confirmation: newConfirmation };
            }
            return row;
        });

        setTableRows(updatedRows);
    };

    return (
        <div
            className='tableDiv'
            style={{
                overflowX: 'auto',
                borderCollapse: 'collapse',
                border: '1px solid #ddd',
                borderRadius: '20px',
                marginBottom: '40px',
                width: '100%', // Set the container width to 100%
                maxWidth: '100%', // Ensure the container doesn't exceed the screen width
            }}
        >
            {isLoading && <LoadingComponent />}
            {(!isLoading && isError) && <ErrorComponent message={'Oops! It seems we\'ve hit a hiccup. Please Try Again Later!'} paddingTop={'16px'}/>}
            {(!isLoading && !isError) && (<>
                    <table
                        style={{
                            tableLayout: 'fixed',
                            width: '100%', 
                            minWidth: '100%', 
                        }}
                    >
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                width: header.getSize(),
                                                textAlign: 'center',
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef.header,
                                                      header.getContext()
                                                  )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                style={{
                                                    textAlign: 'center',
                                                    paddingBottom: '12px',
                                                }}
                                            >
                                                {cell.column.id === 'ai' ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <CustomPopup
                                                            rowId={row.id}
                                                            itemId={row.original.id}
                                                            product={row.original}
                                                            updateConfirmation={updateConfirmation}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {cell.column.id !== 'confirmation' &&  flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </>
                                                )}
                                                {cell.column.id === 'confirmation' && (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {row.original.confirmation === 'approve' && (
                   
                                                            <Check size={20} color='green'/>
                                                        )}
                                                        {row.original.confirmation === 'reject' && (
                                       
                                                            <X size={20} color='red'/>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>)}

            {/* {isLoading ? (
                <LoadingComponent />
            ) : (
                <>
                    <table
                        style={{
                            tableLayout: 'fixed',
                            width: '100%', // Set the table width to 100%
                            minWidth: '100%', // Ensure the table doesn't shrink below the container width
                        }}
                    >
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                width: header.getSize(),
                                                textAlign: 'center',
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef.header,
                                                      header.getContext()
                                                  )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                style={{
                                                    textAlign: 'center',
                                                    paddingBottom: '12px',
                                                }}
                                            >
                                                {cell.column.id === 'ai' ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <CustomPopup
                                                            rowId={row.id}
                                                            itemId={row.original.id}
                                                            product={row.original}
                                                            updateConfirmation={updateConfirmation}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {cell.column.id !== 'confirmation' &&  flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </>
                                                )}
                                                {cell.column.id === 'confirmation' && (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {row.original.confirmation === 'approve' && (
                   
                                                            <Check size={20} color='green'/>
                                                        )}
                                                        {row.original.confirmation === 'reject' && (
                                       
                                                            <X size={20} color='red'/>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            )} */}
        </div>
    );
}
