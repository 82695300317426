import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";

import "./App.css";



function App() {
   // console.log("Environment Variables:- ", process.env);
   return (

     <NextUIProvider>
        <Header />
        <Dashboard />
      </NextUIProvider>

   );
}

export default App;

