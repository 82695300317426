import React from "react";
import { Loading, Text } from "@nextui-org/react";

export default function LoadingComponent({ message }) {
   return (
      <div
         style={{
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
         }}
      >
         <Loading color="warning" textColor="warning" size="xl">
            <Text size="$md" color="warning">{message}</Text>

         </Loading>
      </div>
   );
}
