import React, { useEffect } from "react";
import {
   Navbar, Text, Avatar, Button,
} from "@nextui-org/react";

import { RefreshCw, LogOut } from "@geist-ui/icons";
import logo from "../assets/dashboardLogo.svg";

export default function Header(props) {

   return (

     <Navbar isBordered variant="sticky">
        <Navbar.Brand
           css={{
               "@xs": {
                  w: "auto",
               },
            }}
         >
           <Avatar size="lg" src={logo} />

           <Text size={24} b color="inherit" hideIn="xs" css={{ ml: "10px" }}>
              Pharmacy Planet
                </Text>
         </Navbar.Brand>

        <Navbar.Content
           css={{
               "@xs": {
                  w: "auto",
                  jc: "flex-end",
               },
            }}
           hideIn="xs"
         >
           {/* <Navbar.Item>
              <Button css={{ backgroundColor: "#05668d", color: "white" }} auto icon={<RefreshCw />}>
                 Refresh
                    </Button>

            </Navbar.Item>
           <Navbar.Item>

              <Button css={{ backgroundColor: "#FC582A", color: "white" }} auto icon={<LogOut />}>
                 Sign Out
                    </Button>
            </Navbar.Item> */}
         </Navbar.Content>
        <Navbar.Content
           css={{
               "@xs": {
                  w: "auto",
                  jc: "flex-end",
               },
            }}
           showIn="xs"
         >
           {/* <Navbar.Item>
              <Button css={{ backgroundColor: "#05668d", color: "white" }} auto  icon={<RefreshCw />} />
            </Navbar.Item>
           <Navbar.Item>

              <Button css={{ backgroundColor: "#FC582A", color: "white" }} auto  icon={<LogOut />} />
            </Navbar.Item> */}
         </Navbar.Content>
      </Navbar>

   );
}
